define("discourse/plugins/discourse-manage-message-sending-inusercard/discourse/discourse-manage-message-sending-inusercard-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("discourse-manage-message-sending-inusercard", function () {
      this.route("actions", function () {
        this.route("show", {
          path: "/:id"
        });
      });
    });
  }
  ;
});